import React from 'react';
import { Row, Col, Container } from 'react-bootstrap';
import { Link } from 'gatsby';
import { findBankLogo, findBankName } from '../Offer/OfferHelper.tsx';
import { pad } from './FlowFinish.module.scss';
import useLanguageStore from '../LanguageStore/LanguageStore';
import useFlowProgressStore from '../FlowStore/FlowProgressStore';
import useFlowProviderStore from '../FlowStore/FlowProviderStore';

type LanguageContent = {
  text1: string;
  text2: string;
};

const content: { [name: string]: LanguageContent } = {};
content.English = {
  text1: 'Thanks for choosing your new contract with',
  text2: 'You will contacted within short',
};
content.Español = {
  text1: 'Gracias por eligir tu nuevo contrato con',
  text2: 'Un representante te contactará brevemente',
};

const FlowFinish = () => {
  const flowProgress = useFlowProgressStore((state) => state.flowProgress);
  const chosenProvider = useFlowProviderStore((state) => state.chosenProvider);
  const language = useLanguageStore((state) => state.language);
  let choosenLanguage: LanguageContent;

  language === 'Español'
    ? (choosenLanguage = content.Español)
    : (choosenLanguage = content.English);

  if (flowProgress < 4) {
    return (
      <Container>
        <Link to="/preferencias">Empieza con tus preferencias</Link>
      </Container>
    );
  }

  return (
    <div>
      <Container>
        <Row>
          <div className={pad} />
          <div className={pad} />
          <Col lg="6" md="6" sm="12" xs="12">
            <img
              variant="top"
              src={findBankLogo(parseInt(chosenProvider))}
              alt="Bank Logo"
            />
          </Col>
          <Col lg="6" md="6" sm="12" xs="12">
            <h1 data-testid='thanks'>
              ¡{choosenLanguage.text1} {findBankName(parseInt(chosenProvider))}{' '}
            </h1>
            <p>¡{choosenLanguage.text2}</p>
          </Col>
          <div className={pad} />
          <div className={pad} />
        </Row>
      </Container>
    </div>
  );
};

export default FlowFinish;
